var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Tambah Sharing Fee ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pendapatan Minimum "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Pendapatan Minimum","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"placeholder":"Masukkan pendapatan minimum","state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.minimum_income),callback:function ($$v) {_vm.minimum_income=$$v},expression:"minimum_income"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Nilai Pendapatan Minimum tidak boleh 0' : ''))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Jenis Sharing Fee "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Jenis Sharing Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","options":_vm.sharing_fee_type_option,"reduce":function (option) { return option.value; },"placeholder":"Pilih Jenis Sharing Fee"},model:{value:(_vm.sharing_fee_type),callback:function ($$v) {_vm.sharing_fee_type=$$v},expression:"sharing_fee_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nilai Sharing Fee "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nilai Sharing Fee","rules":("required|integer" + (_vm.sharing_fee_type === 'percentage' ? '|max_value:100' : '|min_value:1'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [(_vm.sharing_fee_type === 'rp')?_c('money',_vm._b({staticClass:"form-control",attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.sharing_fee_value),callback:function ($$v) {_vm.sharing_fee_value=$$v},expression:"sharing_fee_value"}},'money',_vm.money,false)):_c('b-form-input',{attrs:{"placeholder":"Masukkan Nilai Sharing Fee","state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.sharing_fee_value),callback:function ($$v) {_vm.sharing_fee_value=$$v},expression:"sharing_fee_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nilai Maksimal Sharing Fee "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nilai Maksimal Sharing Fee","rules":"required|integer|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.max_nominal_sharing_fee),callback:function ($$v) {_vm.max_nominal_sharing_fee=$$v},expression:"max_nominal_sharing_fee"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Nilai Maksimal Sharing Fee tidak boleh 0' : ''))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalConfirmation",modifiers:{"modalConfirmation":true}}],staticClass:"mr-50 button-primary w-[200px]",attrs:{"disabled":invalid || _vm.loadingSubmit}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan ")],1)])]}}])})],1),_c('ModalConfirmation',{on:{"submit":function($event){return _vm.submit()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }